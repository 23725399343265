import React, { Component } from 'react';
import Home from './Home';

// import { Container } from './styles';

export default class Pages extends Component {
  render() {
    return <div>
        <Home/>
    </div>;
  }
}
