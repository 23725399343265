import React, { Component } from "react";

import "./style.css";

export default class Important extends Component {
  render() {
    return (
      <div className="important_wrapper">
        <div className="important_container">
          <div className="cont">
            <p>
              O plugin de segurança de sites da MAV Tecnologia, pode detectar
              efetivamente qualquer tipo de
              <span>
                malware do site e removê-lo automaticamente usando a limpeza de
                “um clique”,{" "}
              </span>
              mas não fornece uma proteção proativa contra futuros ataques web.
              <br />
              <br />
              Portanto, é altamente recomendável "proteger" seus sites após a
              remoção de malware, seguem algumas dicas:
            </p>
          </div>
          <div className="list_wrapper">
            <ul>
              <li>Atualize a versão do CMS e atualize todos os plugins</li>
              <li>
                Ativar autenticação de dois fatores no painel de administração
                do CMS
              </li>
              <li>
                Defina novas senhas fortes e aleatórias para todas as contas
                (FTP, SSH, ISP, painel de administração)
              </li>
              <li>
                Para administradores do VPS: atualize o SO e os componentes de
                serviço do seu servidor e desative todos os serviços e
                componentes não utilizados
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
