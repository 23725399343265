import React, { useEffect } from "react";
import "./style.css";
import $ from "jquery";

function Menu() {
  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("back");
        } else {
          $(".menu_wrapper").removeClass("back");
        }
      });
    });
  });
  return (
    <div className="menu_wrapper">
      <div className="menu_container">
        <div className="logo_wrapper">
          <div className="logo"></div>
        </div>
        <div className="nav_wrapper">
          <button className="baixar btn">Baixar o plugin</button>
        </div>
      </div>
    </div>
  );
}

export default Menu;
