import React, { Component } from "react";

import "./style.css";

export default class Download extends Component {
  render() {
    return (
      <div className="download_wrapper">
        <div className="download_container">
          <h1>
            Baixe o plugin <strong>agora</strong>
          </h1>
          <p>
            GARANTA MAIOR SEGURANÇA PARA O SITE DA SUA EMPRESA OU DOS SEUS
            CLIENTES.
          </p>
          <button className="baixar btn">Baixar o plugin</button>
        </div>
      </div>
    );
  }
}
