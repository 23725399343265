import React, { Component } from "react";

import "./style.css";
import img1 from "../../../Assets/1.png";
import img2 from "../../../Assets/1.png";
import img3 from "../../../Assets/1.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default class Slide extends Component {
  render() {
    return (
      <div className="slide_wrapper">
        <h1>COMO É REALIZADA A REMOÇÃO DE UM MALWARE</h1>

        <Carousel showThumbs={false} autoPlay interval={5000}>
          <div>
            <img src={img1} alt="slide" />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div>
            <img src={img2} alt="slide" />
            {/* <p className="legend">Legend 2</p> */}
          </div>
          <div>
            <img src={img3} alt="slide" />
            {/* <p className="legend">Legend 3</p> */}
          </div>
        </Carousel>

        <p>
          <strong>O plugin de segurança da MAV Tecnologia,</strong>
          funciona como um antivírus, ele procura o código malicioso nos
          arquivos de um site durante a verificação e mostra os arquivos
          infectados no relatório quando a verificação termina. Se o usuário
          optar por limpar o malware, o antivírus removerá uma parte da injeção
          maliciosa no arquivo ou o arquivo inteiro, dependendo da ameaça
          detectada.
          <br />
          <br />
          Se o arquivo inteiro for um shell da web ou algum outro tipo de
          arquivo malicioso,
          <strong>o antivírus o removerá completamente.</strong>
          Se houver apenas uma pequena injeção no início ou no final ou em algum
          lugar no meio do arquivo, o código malicioso exato será removido, mas
          o conteúdo restante permanecerá inalterado. Normalmente, o antivírus
          remove o malware e mantém um site em funcionamento.
          <br />
          <br />
          Portanto, o antivírus usa uma{" "}
          <strong>limpeza mais segura por padrão </strong>
          para manter o site funcionando corretamente o tempo todo.
        </p>
      </div>
    );
  }
}
