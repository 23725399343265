import React, { Component } from "react";

import "./style.css";
import crawler from '../../../Assets/crawler.svg'
import report from '../../../Assets/report.svg'
import system from '../../../Assets/system.svg'
import antivirus from '../../../Assets/antivirus.svg'
export default class Boxes extends Component {
  render() {
    return (
      <div className="boxes_wrapper">
        <div className="boxes_conatiner">
          <div class="box">
            <div class="icon_wrapper">
              <img
                src={crawler}
                alt="user"
                width="70px"
              />
            </div>
            <div class="texT_container">
              <p>
                <strong>DETECÇÃO DE ARQUIVOS MALICIOSOS</strong>
              </p>
              Detecta qualquer tipo de arquivo malicioso, incluindo backdoors,
              web shells, vírus, ferramentas de hackers, scripts de 'blackhat
              SEO', páginas de phishing e outros
            </div>
          </div>

          <div class="box">
            <div class="icon_wrapper">
              <img
                 src={report}
                alt="user"
                width="70px"
              />
            </div>
            <div class="texT_container">
              <p>
                <strong>MONITORAMENTO DE REPUTAÇÃO</strong>
              </p>
              Monitora a reputação e o status da lista negra de domínios com
              mais de 60 serviços de segurança, incluindo Google SB, Yandex SB,
              McAfee, ESET, Kaspersky
            </div>
          </div>

          <div class="box">
            <div class="icon_wrapper">
              <img
                 src={system}
                alt="user"
                width="70px"
              />
            </div>
            <div class="texT_container">
              <p>
                <strong>SUPORTE A TODAS AS PLATAFORMAS</strong>
              </p>
              Suporta WordPress, Joomla!, Magento, Drupal, MODx, Bitrix e outros
              CMSs baseados em php e sites estáticos em html
            </div>
          </div>

          <div class="box">
            <div class="icon_wrapper">
              <img
                 src={antivirus}
                alt="user"
                width="70px"
              />
            </div>
            <div class="texT_container">
              <p>
                <strong>LIMPEZA COM 1 CLIQUE</strong>
              </p>
              Limpeza automática de malware com um clique, verificações
              agendadas e sob demanda do site e relatórios detalhados
            </div>
          </div>
        </div>
      </div>
    );
  }
}
